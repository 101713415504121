import './index.scss';
import Slide_title from '@component/Slide_title';
import Footer from '@component/Footer';
import { scrollSmoothToY } from '@utils';
import React, { Suspense, Component, useRef, useState, useCallback, useEffect } from 'react';
import imageTool from "@utils/imageTool";

const Partner_slide = (props) => {
  let [line, setLine] = useState(0)
  let row = 4
  let column = 7

  const adjustToWindow = useCallback(() => {
    let container = document.getElementById('partnerList')

    let box = document.getElementsByClassName('partner_item')[line * column]
    if (!container || !box) return
    scrollSmoothToY(container, (box.clientHeight + parseInt(window.getComputedStyle(box).getPropertyValue('margin-top'))) * line)
  }, [column, line])
  window.onresize = () => {
    adjustToWindow()
  }
  useEffect(() => {
    adjustToWindow()
  }, [adjustToWindow])
  return <div className="Partner_slide">

    <div className="main">
      <div className="center">
        <Slide_title title={props.title}></Slide_title>
        <div className="partnerList" id="partnerList">
          {
            props.indexPartnerVoList?.map((partner, p_index) => {
              return <a className="partner_item" href={partner.href || null} target="_blank" rel="noopener noreferrer" key={'partner_item_' + p_index} id={'partner_item' + p_index}>
                <img src={imageTool.adjustWidth(partner.img, 210)} className="partner_img" alt=""></img>
                <div className="partner_name">
                  {partner.companyName}
                </div>
                <div className="partner_mask">
                  <img className="partner_logo" src={imageTool.adjustWidth(partner.logo, 210)} alt=""></img>
                </div>
              </a>
            })
          }
        </div>
        {
          props.indexPartnerVoList?.length > row * column ? <div className="pageOpts">
            <div className="opt partner_prev"
              style={{ opacity: `${line == 0 ? 0.4 : 1}` }}
              onClick={() => {
                if (line == 0) return
                setLine(line - 1)
              }}></div>
            <div className="opt partner_next"
              style={{ opacity: `${parseInt(props.indexPartnerVoList?.length / column) < line + row ? 0.4 : 1}` }}
              onClick={() => {
                if (parseInt(props.indexPartnerVoList?.length / column) < line + row)
                  return
                setLine(line + 1)
              }}></div>
          </div> : null
        }
      </div>
      <Footer></Footer>
    </div>
  </div>
}

export default Partner_slide;