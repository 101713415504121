import Request from '@utils/request';
import { sessionStorage } from '@utils/store';
import { IndexMenuData, HotProducts_ID, HotProducts_Name, HotProducts_Data} from '@utils/constant';

export const getMenuData = (isAgain) => {

  return new Promise((resolve, reject) => {
    let data = sessionStorage.getItem(IndexMenuData)
    // 若参数为true或缓存没有数据 则重新获取菜单栏数据
    if (isAgain == true || !data) {
      Request.post('/website/site/menuData').then(res => {
        if (res.errorCode == 0) {
          res.result.categoryVoList.unshift({
            catId: HotProducts_ID,
            catName: HotProducts_Name
          })
          sessionStorage.setItem(IndexMenuData, res.result)
          resolve(res.result)
        } else {
          reject()
        }
      })
    } else {
      resolve(data)
    }
  })
}

export const getIndexData = () => {
  return new Promise(resolve => {
    Request.post('/website/site/index').then(res => {
      if (res.errorCode == 0) {
        sessionStorage.setItem(HotProducts_Data, res.result.productSetVoList)
        resolve(res.result)
      }
    })
  })
}

export const getNewsCategoryNameById = (catId) => {
  let data = sessionStorage.getItem(IndexMenuData)
  if (!data) return null
  let result = data.newsCategoryList.find(item => {
    return item.catId == catId
  })
  return result.catName
}

