import React, { Suspense, lazy, useState, useContext, useEffect, useRef } from 'react'
import { useLocation, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import './index.scss';
import { sessionStorage } from '@utils/store';

var MenuConfig = [{
  label: '首页',
  path: '/',
  subMenu: []
}, {
  label: '新闻资讯',
  path: '/news',
  subMenu: []
}, {
  label: '关于思埠',
  path: '/about',
  subMenu: [{
    name: '思埠介绍',
    path: '/about',
  }, {
    name: '走进思埠',
    path: '/general',
  }, {
    name: '思埠公告',
    path: '/notice',
  }]
}, {
  label: '品牌中心',
  path: '/brand',
  subMenu: []
}, {
  label: '在线查询',
  path: '/query/certificate',
  subMenu: [{
    name: '资质证书',
    path: '/query/certificate',
  }, {
    name: '防伪查询',
    path: '/query/quality',
  },
  // 授权查询(0831移除)
  // {
  //   name: '授权查询',
  //   path: '/query/grant',
  // }
  ]
}]

const Menu = props => {
  let history = useHistory()
  let location = useLocation()

  let [m_index, setM_index] = useState(sessionStorage.getItem('sibu_menu')?.m_index || -1)
  // let [s_index, setS_index] = useState(sessionStorage.getItem('sibu_menu')?.s_index || -1)
  // 正确做法应该是下面根据 url 匹配相应的菜单选项
  // 之前还在使用 sessionStorage
  // let m_index = sessionStorage.getItem('sibu_menu')?.m_index || -1
  // let s_index = sessionStorage.getItem('sibu_menu')?.s_index || -1

  let data = props.data
  useEffect(() => {
    // 配置【新闻资讯（分类）】子菜单
    if (!data) return
    MenuConfig[1].path = '/news'
    MenuConfig[1].search = '?catId=' + data.newsCategoryList[0].catId
    MenuConfig[1].subMenu = []
    data.newsCategoryList?.forEach((news_cat, c_index) => {
      MenuConfig[1].subMenu.push({
        name: news_cat.catName,
        path: '/news',
        search: '?catId=' + news_cat.catId
      })
    })
    // 配置【思埠公告】最新的公告id
    MenuConfig[2].subMenu[2].path = '/notice'
    // MenuConfig[2].subMenu[2].search = '?id=' + data.noticeId

    // 配置【品牌中心】
    MenuConfig[3].subMenu = []
    data.categoryVoList?.forEach((category, b_index) => {
      MenuConfig[3].subMenu.push({
        name: category.catName,
        path: '/brand',
        search: '?category=' + category.catId
      })
    })
    // 根据 url 匹配相应的菜单选项
    MenuConfig.forEach((menu, m_index) => {
      let url = location.pathname + location.search
      let menuUrl = menu.path + (menu.search || '')
      if (menuUrl === url || menu.path === location.pathname) {
        setM_index(m_index)
      } else {
        menu.subMenu.forEach((sub, s_index) => {
          let subMenuUrl = sub.path + (sub.search || '')
          if (subMenuUrl === url || sub.path === location.pathname) {
            setM_index(m_index)
          }
        })
      }
    })
  }, [data])// eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = (path = '/', search = '', state = {}, m_index = -1, s_index = -1) => {
    // console.log(index, s_index)
    // // 路由route的path和search
    // console.log(path)
    // console.log(search)
    // // 浏览器location的path和search
    // console.log(location)
    // console.log(location.pathname)
    // console.log(location.search)
    // sessionStorage.setItem('sibu_menu', { m_index, s_index })
    // setM_index(m_index)
    // setS_index(s_index)
    try {
      if (path + search === location.pathname + location.search) {
        window.location.reload()
      } else if (path === location.pathname) {
        history.replace(path + search, state)
      } else {
        history.push(path + search, state)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return <>
    <div className="Menu">
      {
        MenuConfig.map((item, index) => {
          return <div className="item" key={item.label}>
            <div className={`label ${m_index === index ? 'label_active' : ''}`} onClick={() => { handleClick(item.path, item.search, item.state, index, -1) }}>{item.label}</div>
            <div className="subMenu" >
              {
                item.subMenu.map((subItem, s_index) => {
                  return <div className="subOption" onClick={() => { handleClick(subItem.path, subItem.search, subItem.state, index, s_index) }} key={subItem.name}>{subItem.name}</div>
                })
              }
            </div>
          </div>
        })
      }
    </div>
  </>
}

export default Menu