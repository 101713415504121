
// 封装 localStorage 本地存储
export const sessionStorage = {
  setItem: (key, value) => {
    if (key && value) {
      return window.sessionStorage.setItem(key, JSON.stringify(value))
    } else {
      throw new Error('关键字和value不能为空！')
    }
  },
  getItem: (key) => {
    if (key) {
      return JSON.parse(window.sessionStorage.getItem(key))
    } else {
      throw new Error('关键字不能为空！')
    }
  },
  removeItem: (key) => {
    if (key) {
      window.sessionStorage.removeItem(key)
    } else {
      throw new Error('关键字不能为空！')
    }
  },
  clear: () => {
    window.sessionStorage.clear()
  }
}

// 封装 sessionStorage 浏览器存储
export const localStorage = {
  setItem: (key, value) => {
    if (key && value) {
      return window.localStorage.setItem(key, JSON.stringify(value))
    } else {
      throw new Error('关键字和value不能为空！')
    }
  },
  getItem: (key) => {
    if (key) {
      return JSON.parse(window.localStorage.getItem(key))
    } else {
      throw new Error('关键字不能为空！')
    }
  },
  removeItem: (key) => {
    if (key) {
      window.localStorage.removeItem(key)
    } else {
      throw new Error('关键字不能为空！')
    }
  },
  clear: () => {
    window.localStorage.clear()
  }
}