import './index.scss';
import { useHistory } from 'react-router-dom';
import imageTool from "@utils/imageTool";

const Product_slide = (props) => {
  let history = useHistory()

  const seeProductDetail = (goodsId) => {
    // 点击产品，跳到对应的产品详情页
    if (!goodsId) return
    history.push(`/product/detail/${goodsId}`, {
      id: goodsId
    })
  }
  return <div className="Product_slide">
    <div className="main">
      <div className="center">
        {/* 下标是横着数的，因此左边 0 3 4 右边 1 2 5 */}
        {
          props.productSetVoList ? <div className="products">
            <div className="left">
              <div className="top">
                <div className={`big product`} onClick={() => { seeProductDetail(props.productSetVoList[0]?.goodsId) }}>
                  <div className="mask">
                    <div className="product_name">{props.productSetVoList[0]?.goodsName}</div>
                    <div className="product_intro">
                      {
                        props.productSetVoList[0]?.sellPoint?.split('\n').map((i, i_index) => {
                          return <span key={i_index}>{i}<br /></span>
                        })
                      }
                    </div>
                    <div className="seeMore">详情</div>
                  </div>
                  <img src={imageTool.adjustWidth(props.productSetVoList[0]?.image, 650)} className="product_img" alt={props.productSetVoList[0]?.goodsName}></img>
                </div>
              </div>
              <div className="bot">
                <div className={`small product`} onClick={() => { seeProductDetail(props.productSetVoList[3]?.goodsId) }}>
                  <div className="mask">
                    <div className="product_name">{props.productSetVoList[3]?.goodsName}</div>
                    <div className="product_intro">
                      {
                        props.productSetVoList[3]?.sellPoint?.split('\n').map((i, i_index) => {
                          return <span key={i_index}>{i}<br /></span>
                        })
                      }
                    </div>
                    <div className="seeMore">详情</div>
                  </div>
                  <img src={imageTool.adjustWidth(props.productSetVoList[3]?.image, 315)} className="product_img" alt={props.productSetVoList[3]?.goodsName}></img>
                </div>
                <div className={`small product`} onClick={() => { seeProductDetail(props.productSetVoList[4]?.goodsId) }}>
                  <div className="mask">
                    <div className="product_name">{props.productSetVoList[4]?.goodsName}</div>
                    <div className="product_intro">
                      {
                        props.productSetVoList[4]?.sellPoint?.split('\n').map((i, i_index) => {
                          return <span key={i_index}>{i}<br /></span>
                        })
                      }
                    </div>
                    <div className="seeMore">详情</div>
                  </div>
                  <img src={imageTool.adjustWidth(props.productSetVoList[4]?.image, 315)} className="product_img" alt={props.productSetVoList[4]?.goodsName}></img>
                </div>
              </div>
            </div>
            <div className="right">
              <div className="top">
                <div className={`small product`} onClick={() => { seeProductDetail(props.productSetVoList[1]?.goodsId) }}>
                  <div className="mask">
                    <div className="product_name">{props.productSetVoList[1]?.goodsName}</div>
                    <div className="product_intro">
                      {
                        props.productSetVoList[1]?.sellPoint?.split('\n').map((i, i_index) => {
                          return <span key={i_index}>{i}<br /></span>
                        })
                      }
                    </div>
                    <div className="seeMore">详情</div>
                  </div>
                  <img src={imageTool.adjustWidth(props.productSetVoList[1]?.image, 315)} className="product_img" alt={props.productSetVoList[1]?.goodsName}></img>
                </div>
                <div className={`small product`} onClick={() => { seeProductDetail(props.productSetVoList[2]?.goodsId) }}>
                  <div className="mask">
                    <div className="product_name">{props.productSetVoList[2]?.goodsName}</div>
                    <div className="product_intro">
                      {
                        props.productSetVoList[2]?.sellPoint?.split('\n').map((i, i_index) => {
                          return <span key={i_index}>{i}<br /></span>
                        })
                      }
                    </div>
                    <div className="seeMore">详情</div>
                  </div>
                  <img src={imageTool.adjustWidth(props.productSetVoList[2]?.image, 315)} className="product_img" alt={props.productSetVoList[2]?.goodsName}></img>
                </div>
              </div>
              <div className="bot">
                <div className={`big product`} onClick={() => { seeProductDetail(props.productSetVoList[5]?.goodsId) }}>
                  <div className="mask">
                    <div className="product_name">{props.productSetVoList[5]?.goodsName}</div>
                    <div className="product_intro">
                      {
                        props.productSetVoList[5]?.sellPoint?.split('\n').map((i, i_index) => {
                          return <span key={i_index}>{i}<br /></span>
                        })
                      }
                    </div>
                    <div className="seeMore">详情</div>
                  </div>
                  <img src={imageTool.adjustWidth(props.productSetVoList[5]?.image, 650)} className="product_img" alt={props.productSetVoList[5]?.goodsName}></img>
                </div>
              </div>
            </div>
          </div> : null
        }

        <div className="seeMore_button" onClick={() => {
          // 跳到品牌中心页面
          history.push('/brand')
        }}>查看更多</div>
      </div>
    </div>
  </div>
}

export default Product_slide;