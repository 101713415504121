import React, { Component, useRef, useState, useCallback } from 'react';
import './index.scss';
// 导入基本swiper组件
import Swiper from "swiper";
import 'swiper/dist/js/swiper.js';
import 'swiper/dist/css/swiper.min.css';
import { withRouter } from "react-router-dom";
import Sibu_Introduction from '@view/AboutSibu/Sibu_Introduction';
import Sibu_DevHistory from '@view/AboutSibu/Sibu_DevHistory';
import Sibu_Honor from '@view/AboutSibu/Sibu_Honor';
import Sibu_FollowUs from '@view/AboutSibu/Sibu_FollowUs';

class Vertical_swiper extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    new Swiper(".swiper-container-AboutSibu", {
      slidesPerView: 1,//同屏显示多少个swiper滑块
      autoplay: false,//自动轮播开启
      direction: 'vertical',
      mousewheel: true,
      spaceBetween: 50,// 与下一屏的空白距离
      initialSlide: 0,//默认展示第几个滑块
      loop: false,//是否开启无限轮播
      lazy: {
        loadPrevNext: true,
      },
      pagination: {
        el: '.swiper-pagination',//开启分页器操作
        clickable: true
      },
      observer: true,//修改swiper自己或子元素时，自动初始化swiper
      observeParents: true,//修改swiper的父元素时，自动初始化swiper
    });
  }
  render() {
    let props = this.props
    return <div className="vertical_swiper">
      <div className={`swiper-container swiper-container-AboutSibu`}>
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <Sibu_Introduction title="思埠介绍" data={props.allData.companyOverviewEntity}></Sibu_Introduction>
          </div>
          <div className="swiper-slide">
            <Sibu_DevHistory title="思埠发展历程" data={props.allData.progressVoList}></Sibu_DevHistory>
          </div>
          <div className="swiper-slide">
            <Sibu_Honor title="思埠荣誉" data={props.allData.honoraryVoList}></Sibu_Honor>
          </div>
          <div className="swiper-slide">
            <Sibu_FollowUs title="关注我们"></Sibu_FollowUs>
          </div>
        </div>
        {/* 分页器 */}
        <div className="swiper-pagination"></div>
      </div>
    </div>
  }
}

export default withRouter(Vertical_swiper);