import React, { Component, useRef, useState, useCallback } from 'react';
import './index.scss';
// 导入基本swiper组件
import Swiper from "swiper";
import 'swiper/dist/js/swiper.js';
import 'swiper/dist/css/swiper.min.css';
import { withRouter } from "react-router-dom";
import imageTool from "@utils/imageTool";

class Horizontal_swiper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialSlide: 0
    };
  }
  componentDidMount() {
    setTimeout(() => {
      new Swiper(".swiper-container-h", {
        slidesPerView: 1,//同屏显示多少个swiper滑块
        autoplay: {   //滑动后继续播放（不写官方默认暂停）
          disableOnInteraction: false,
        },//自动轮播开启
        direction: 'horizontal',
        mousewheel: false,
        pagination: {
          el: '.swiper-pagination-h',
          clickable: true
        },
        spaceBetween: 0,// 与下一屏的空白距离
        initialSlide: 0,//默认展示第几个滑块
        loop: true,//是否开启无限轮播
        lazy: {
          loadPrevNext: true,
        },
        observer: true,//修改swiper自己或子元素时，自动初始化swiper
        observeParents: true,//修改swiper的父元素时，自动初始化swiper
      });
    }, 500) //不加延时的话 loop: true 不生效, 可能是需要等待渲染完成
  }
  render() {
    let props = this.props
    return <div className="horizontal_swiper">
      <div className={`swiper-container swiper-container-h`}>
        {/* 分页器 */}
        <div className="swiper-pagination swiper-pagination-h"></div>
        <div className="swiper-wrapper">
          {
            props.indexData.bannerVoList?.map((bannerVo, b_index) => {
              return <div className="swiper-slide" key={`bannerVo_${b_index}`}>
                <a href={bannerVo.href} target="_blank" rel="noopener noreferrer">
                  <div className="swiper_img" style={{ backgroundImage: `url('${imageTool.adjustWidth(bannerVo.image, 1920)}')` }}></div>
                </a>
              </div>
            })
          }
        </div>
      </div>
    </div>
  }
}

export default withRouter(Horizontal_swiper);