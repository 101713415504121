import './index.scss';

const Footer = (props) => {
  return <div className="Footer">
    <div className="phone">
      联系电话：020-22969999、020-86909999
    </div>
    <div className="address">
      地址：广东省广州市花都区汇晶西一街4号广物汇晶广场S4整栋（思埠集团）
    </div>
    <a className="record_number" href="https://beian.miit.gov.cn">
      粤ICP备2023030276 Copyright © 2015-2023 广东思埠集团有限公司
      {/* Copyright © 2015-2023 广东思埠集团有限公司 */}
    </a>
  </div>
}

export default Footer