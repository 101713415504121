import './index.scss';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useLocation, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import Slide_title from '@component/Slide_title';
import { sessionStorage } from '@utils/store';
import { IndexMenuData } from '@utils/constant';
import imageTool from "@utils/imageTool";

const Latest_news = (props) => {
  let history = useHistory()
  return <div className="Latest_news">
    <div className="main">
      <div className="center">
        <Slide_title title={props.title}></Slide_title>
        <div className="newsList">
          {
            props.indexNewsVoList?.map((news, n_index) => {
              return n_index < 3 ? <div className="news_item" key={'news_item' + news.id} onClick={() => {
                history.push(`/news/detail?id=${news.id}`)
              }}>
                <img className="img" src={imageTool.adjustWidth(news.coverImage, 425)} alt=""></img>
                <div className="content">
                  <div className="date">{news.date || '2021-06'}</div>
                  <div className="title">{news.title}</div>
                  <div className="intro">{news.intro}</div>
                  <div className="read_Detail"></div>
                </div>
              </div>: null
            })
          }
        </div>
        {/* 跳到新闻资讯下的集团动态列表页 */}
        <div className="seeMore_button" onClick={() => {
          let data = sessionStorage.getItem(IndexMenuData)
          history.push('/news?catId=' + data.newsCategoryList[0].catId)
        }}>
          查看更多
      </div>
      </div>
    </div>
  </div>
}

export default Latest_news;