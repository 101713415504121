import './index.scss';
import React, { Component, useRef, useState, useEffect, useCallback } from 'react';
import Slide_title from '@component/Slide_title';
import { scrollSmoothToX } from '@utils';

const Sibu_DevHistory = (props) => {
  let progressVoList = props.data
  let progressVoListLength = progressVoList?.length || 0
  let [screen, setScreen] = useState(1)
  let screenSize = 7
  let screenLength = parseInt(progressVoListLength / screenSize) + (progressVoListLength % screenSize !== 0 ? 1: 0)
  let emptys = screenSize - progressVoListLength % screenSize
  const turn_page = async (direction) => {
    if (direction == 'prev' && screen == 1) return
    if (direction == 'next' && screen == screenLength) return
    let screenWidth = document.getElementById('HistoryList').offsetWidth
    if (direction == 'prev') {
      setScreen(screen - 1)
      scrollSmoothToX(document.getElementById('HistoryList'), (screen - 2) * screenWidth)
    }
    if (direction == 'next') {
      setScreen(screen + 1)
      scrollSmoothToX(document.getElementById('HistoryList'), (screen) * screenWidth)
    }
  }

  return <div className="Sibu_DevHistory">
    <Slide_title title={props.title}></Slide_title>
    <div className="content">
      <div className={`arrow arrow_left ${screen == 1 ? 'arrow_left_disable' : ''}`} onClick={() => { turn_page('prev') }}></div>
      <div className="HistoryList" id="HistoryList">
        {
          progressVoList?.map((deeds, d_index) => {
            return <div className="deeds_item" key={'enterprise_deeds_' + d_index}>
              <div className="date">{deeds.evTime}</div>
              <div className="deeds">
                <div className="text">{deeds.event}</div>
                <div className="tag"></div>
              </div>
            </div>
          })
        }
        {/* 补足screenSize(7)的倍数 */}
        {
          [...Array(emptys === screenSize ? 0 : emptys)].map((e, i) => {
            return <div className="deeds_item invisible" key={'enterprise_deeds_empty_' + i}></div>
          })
        }
      </div>
      <div className={`arrow arrow_right ${screen == screenLength ? 'arrow_right_disable' : ''}`} onClick={() => { turn_page('next') }}></div>
    </div>
  </div>
}

export default Sibu_DevHistory;

