import { BrowserRouter as Router, Route, Link, Switch, Redirect, withRouter } from "react-router-dom";
import React, { Component, useRef, useState, useCallback } from 'react';
import { CacheRoute, CacheSwitch } from 'react-router-cache-route'
import { setDirectScrollTop } from '@utils'

import { createRouter, getRouter } from '@/router/createRouter'
import routes_Home from '@/router/modules/Home'
import routes_AboutSibu from '@/router/modules/AboutSibu'
import routes_News from '@/router/modules/News'
import routes_BrandCenter from '@/router/modules/BrandCenter'
import routes_OnlineQuery from '@/router/modules/OnlineQuery'
import routes_VideoCenter from '@/router/modules/VideoCenter'

// 路由配置对象
const RouterConfig = [
  ...routes_Home,
  // ...routes_AboutSibu,
  // ...routes_News,
  // ...routes_BrandCenter,
  // ...routes_OnlineQuery,
  // ...routes_VideoCenter
]
createRouter(RouterConfig) // 根据配置对象生成路由对象数组

// ======================================
//跳转页面后，置顶
// （解决react-router Link跳转后不返回页面顶部的问题：https://blog.csdn.net/u013790941/article/details/103061332）
class AutoScrollTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      // let element = document.body || document.documentElement
      // setScrollTop(element, 0)
      setDirectScrollTop(0)
    }
  }
  render() {
    return this.props.children
  }
}
const AutoScrollTopProvider = withRouter(AutoScrollTop)
// ======================================

export default (
  <AutoScrollTopProvider>
    <CacheSwitch>
      {getRouter()}
      <Redirect from="/*" to="/" />
    </CacheSwitch>
  </AutoScrollTopProvider>
)