import './index.scss';
import { useState } from 'react';
import imageTool from "@utils/imageTool";

const Sibu_Introduction = (props) => {
  return <div className="Sibu_Introduction">
    <div className="bgImg" style={{ backgroundImage: `url('${imageTool.adjustWidth(props.data?.bannerBackground, 1920)}')` }}></div>
    <div className="box">
      <div className="video_item"
      >
        <video id="video" className="video" src={props.data?.videoUrl} controls></video>
      </div>
      <div className="text_item">
        {
          props.data?.intro?.split('\n').map((i, i_index)=>{
            return <span key={i_index}>{i}<br/></span>
          })
        }
      </div>
    </div>
  </div>
}

export default Sibu_Introduction;


