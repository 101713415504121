import React, { Component, useRef, useState, useCallback } from 'react';
import './index.scss';
// 导入基本swiper组件
import Swiper4 from "swiper";
import 'swiper/dist/js/swiper.js';
import 'swiper/dist/css/swiper.min.css';
import { withRouter } from "react-router-dom";
import imageTool from "@utils/imageTool";
class Products_Swiper extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    new Swiper4('.swiper-container-xxx' + this.props.current_index, {
      slidesPerView: 3,//同屏显示多少个swiper滑块
      autoplay: false,//自动轮播开启
      direction: 'horizontal',
      mousewheel: false,
      spaceBetween: '1.5%',// 与下一屏的空白距离
      initialSlide: 0,//默认展示第几个滑块
      loop: false,//是否开启无限轮播
      navigation: {
        prevEl: '.swiper-button-prev' + this.props.current_index,
        nextEl: '.swiper-button-next' + this.props.current_index
      },
      observer: true,//修改swiper自己或子元素时，自动初始化swiper
      observeParents: true,//修改swiper的父元素时，自动初始化swiper
    });
  }
  render() {
    let { history } = this.props
    let goodsVoList = this.props.data.goodsCategoryVoList[this.props.current_index].goodsVoList

    if (goodsVoList.length > 0) {
      return <div className="Products_swiper" key={`Products_swiper${this.props.current_index}`}>
        {/* 重写覆盖部分swiper代码 */}
        {/* 1. swiper 本身的箭头在里面；而我们需要箭头在外面； */}
        {/* 在swiper-container(<Swiper>) 外面增加一个包裹，也就是套一个div(Products_swiper)，给这个div增加相对定位position:relative;*/}
        {/* 把左右箭头的代码添加在swiper-container外面同一级 */}
        {/* 2. 因为此页面有多个swiper，因为翻页器className需添加指定key
      navigation={{
        nextEl: '.swiper-button-next' + props.current_index,
        prevEl: '.swiper-button-prev' + props.current_index
      }} */}
        <div className={`swiper-button-prev swiper-button-prev${this.props.current_index} ${goodsVoList.length <= 3 ? 'swiper-button-disabled' : ''}`}>
          <div className="button gggg_prev_icon"></div>
        </div>
        <div className={`swiper-button-next swiper-button-next${this.props.current_index} ${goodsVoList.length <= 3 ? 'swiper-button-disabled' : ''}`}>
          <div className="button gggg_next_icon"></div>
        </div>
        <div className={`swiper-container swiper-container-xxx${this.props.current_index}`}>
          <div className="swiper-wrapper">
            {
              goodsVoList.map((product, p_index) => {
                return <div className="swiper-slide" key={`c${this.props.current_index}_p${p_index}`}>
                <div className="product_item" onClick={() => {
                  history.push(`/product/detail/${product.id}`, {
                    isHotProduct: false
                  })
                }}>
                  <div className="img">
                    <img src={imageTool.adjustWidth(product.coverImage, 415)} alt=""></img>
                  </div>
                  <div className="box">
                    <div className="title">{product.goodsName}</div>
                    <div className="desc">
                      {
                        product.sellPoints?.split('\n').map((i, i_index) => {
                          return <span key={i_index}>{i}<br /></span>
                        })
                      }
                    </div>
                  </div>
                </div>
              </div>
              })
            }
            {/* 少于3个需要补足3个 */}
            {
              goodsVoList.length < 3 ? [...Array(3 - goodsVoList.length)].map((e, i) => {
                return <div className="swiper-slide" key={`empty_${i}`}>
                  <div className="product_item"></div>
                </div>
              }) : null
            }
          </div>
        </div>
      </div >
    } else {
      return null
    }
  }
}

export default withRouter(Products_Swiper);
