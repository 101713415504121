import './index.scss';
import QRcode_Group from '@images/Header/QRcode_Group.jpg';
import QRcode_Youxuan from '@images/Header/QRcode_Youxuan.jpg';
import Slide_title from '@component/Slide_title';
import Footer from '@component/Footer';

const Sibu_FollowUs = (props) => {
  return <div className="Sibu_FollowUs">
    <Slide_title title={props.title}></Slide_title>
    <div className="QRcodeList">
      <div className="section1">
        <div className="QRcode1">
          <img src={QRcode_Group} className="QRcode" alt="思埠集团"></img>
        </div>
        <div className="QRcodeText">扫码关注思埠集团公众号</div>
      </div>
      <div className="section2">
        <div className="QRcode2">
          <img src={QRcode_Youxuan} className="QRcode" alt="思埠优选"></img>
        </div>
        <div className="QRcodeText">扫码关注思埠优选公众号</div>
      </div>
    </div>
    <div style={{ width: '100%', position: 'absolute', bottom: 0, right: 0 }}>
      <Footer></Footer>
    </div>
  </div>
}

export default Sibu_FollowUs;

