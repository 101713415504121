import React, { Component, useRef, useState, useCallback } from 'react';
import './index.scss';
// 导入基本swiper组件
import Swiper from "swiper";
import 'swiper/dist/js/swiper.js';
import 'swiper/dist/css/swiper.min.css';
import { withRouter } from "react-router-dom";
import { scrollSmoothToY } from '@utils';
import imageTool from "@utils/imageTool";

class Category_Swiper extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    new Swiper(".swiper-container" + this.props.current_index, {
      slidesPerView: 5,//同屏显示多少个swiper滑块
      autoplay: false,//自动轮播开启
      direction: 'horizontal',
      spaceBetween: '1.25%',// 与下一屏的空白距离
      initialSlide: 0,//默认展示第几个滑块
      loop: false,//是否开启无限轮播
      navigation: {
        nextEl: '.swiper-button-next' + this.props.current_index,
        prevEl: '.swiper-button-prev' + this.props.current_index
      },
      lazy: {
        loadPrevNext: true,
      },
      observer: true,//修改swiper自己或子元素时，自动初始化swiper
      observeParents: true,//修改swiper的父元素时，自动初始化swiper
    });
  }
  render() {
    let props = this.props
    return <div className="Products_swiper">
      {/* 重写覆盖部分swiper代码 */}
      {/* 1. swiper 本身的箭头在里面；而我们需要箭头在外面； */}
      {/* 在swiper-container(<Swiper>) 外面增加一个包裹，也就是套一个div(Products_swiper)，给这个div增加相对定位position:relative;*/}
      {/* 把左右箭头的代码添加在swiper-container外面同一级 */}
      {/* 2. 因为此页面有多个swiper，因为翻页器className需添加指定key
    navigation={{
      nextEl: '.swiper-button-next' + props.current_index,
      prevEl: '.swiper-button-prev' + props.current_index
    }} */}
      {
        props.data?.length > 5 ? <>
          <div className={`swiper-button-prev swiper-button-prev${props.current_index}`}></div>
          <div className={`swiper-button-next swiper-button-next${props.current_index}`}></div>
        </> : null
      }
      <div className={`swiper-container swiper-container${props.current_index}`}>
        <div className="swiper-wrapper">
          {
            props.data?.map((video, p_index) => {
              return <div className="swiper-slide" key={`c${props.current_index}_p${video.id}`}>
                <div className="product_item" onClick={() => {
                  if (!video.videoUrl) {
                    alert('暂无视频链接')
                    return
                  }
                  let body = document.body || document.documentElement
                  scrollSmoothToY(body, 0)
                  props.history.replace('/video?id=' + video.id)
                  props.setCurVideo(video)
                }}>
                  <div className="img">
                    <video className="img" poster={imageTool.adjustWidth(video.coverImage, 315)} src={video.videoUrl} id={'videoInnerVoList_' + video.id}></video>
                    <div className="duration" id={'videoInnerVoList_duration' + video.id}>
                      {video.durantion}
                    </div>
                  </div>
                  <div className="box">
                    <div className="title">{video.videoName}</div>
                  </div>
                </div>
              </div>
            })
          }
          {
            props.data?.length < 5 ? [...Array(5 - props.data?.length)].map((e, i) => {
              return <div className="swiper-slide" key={`empty_${i}`}>
                <div className="product_item"></div>
              </div>
            }) : null
          }
        </div>
      </div>
    </div>
  }
}

export default withRouter(Category_Swiper);
