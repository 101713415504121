import React, { Suspense, lazy, useState, useContext, useEffect, useRef } from 'react'
import { useLocation, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { Link } from "react-router-dom";
import './index.scss';
import { Popover, Button } from 'antd';
import Menu from '@/component/Menu';
import img_logo from '@images/Header/logo@2x.png';
import QRcode_Group from '@images/Header/QRcode_Group.jpg';
import { getMenuData } from '@utils/api/common';

const Header = (props) => {
  let history = useHistory()
  let location = useLocation()
  const content = (
    <img style={{ width: '170px', height: '170px' }} src={QRcode_Group} alt=""></img>
  );
  let [showQRCode, setShowQRCode] = useState(false)
  let [menuData, setMenuData] = useState(null)

  useEffect(() => {
    getMenuData(false).then((data) => {
      setMenuData(data)
    })
  }, [location.pathname, location.search])

  return <div className="Header" style={{ background: props.background || 'transparent' }}>
    <div className="left">
      <img className="img_logo" src={img_logo} alt="" onClick={() => {
        history.replace('/')
        window.location.reload()
      }}></img>
    </div>
    <div className="right">
      <div className="menu">
        <Menu data={menuData}></Menu>
      </div>
      <div className="options">
        {/* 1.微信icon：鼠标移到微信icon上，显示 当前首页二维码 */}
        {/* <Popover content={content} title="" onVisibleChange={(bool) => {
          setShowQRCode(bool)
        }}>
          <div className="option">
            <div className={`icon ${showQRCode ? 'icon_wechat_active' : 'icon_wechat'}`}></div>
          </div>
        </Popover> */}
        {/* 2.点击站内消息的icon，跳到思埠公告页面 */}
        <div className="option">
          <div className="icon icon_clock" onClick={() => {
            history.push('/notice')
          }}></div>
        </div>
      </div>
    </div>
  </div>
}

export default Header

