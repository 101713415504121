// OSS图片处理
// https://help.aliyun.com/document_detail/44688.html

// 按宽度适配图片宽高，[默认m 参数 lfit]
function adjustWidth(url, width) {
  if(!url) return ''
  return `${url}?x-oss-process=image/resize,w_${width},m_lfit`
}

function adjustHeight(url, height) {
  if(!url) return ''
  return `${url}?x-oss-process=image/resize,h_${height},m_lfit`
}

const imageTool = {
  adjustWidth,
  adjustHeight
}

export default imageTool