import './index.scss';
import Header from '@/component/Header';
import Footer from '@/component/Footer';
import ScrollTopTool from '@/component/ScrollTopTool';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { formatSearchToJson } from '@utils';
import { getYearArray } from '@utils/time';
import Request from '@utils/request';
import moment from 'moment';
import { getNewsCategoryNameById } from '@utils/api/common';
import { IndexMenuData } from '@utils/constant';
import { sessionStorage } from '@utils/store';
import { getSelected } from '@utils';
import imageTool from "@utils/imageTool";


// 历年来+ (2014-当前年份)
const AllYear = ''
const AllYearText = '所有动态'
const YearArray = [AllYear, ...getYearArray(2021)]

const NewsList = (props) => {
  let history = useHistory()
  let location = useLocation()
  let [selectYear, setSelectYear] = useState(YearArray[0])
  let [currentPage, setCurrentPage] = useState(1)
  let [list, setList] = useState([])
  let [pageSize, setPageSize] = useState(8)
  let [totalCount, setTotalCount] = useState(0)
  let [totalPage, setTotalPage] = useState(0)
  let catId = formatSearchToJson(location.search).catId
  let [catName, setCatName] = useState('')

  useEffect(() => {
    Request.get('/website/site/newsList', {
      catId: catId,
      currentPage: currentPage,
      pageSize: pageSize,
      year: selectYear
    }).then(res => {
      if (res.errorCode == 0) {
        let { currPage, list, totalCount, totalPage } = res.result
        setList(list)
        setTotalCount(totalCount)
        setTotalPage(totalPage)
      }
    })
  }, [catId, currentPage, pageSize, selectYear, location.search])

  useEffect(() => {
    setCatName(getNewsCategoryNameById(formatSearchToJson(location.search).catId))
  }, [sessionStorage.getItem(IndexMenuData)])// eslint-disable-line react-hooks/exhaustive-deps

  return <div className="NewsList" >
    <div style={{ width: '100%', position: 'fixed', top: 0, left: 0, zIndex: 999 }}>
      <Header background={'#FFF'}></Header>
    </div>
    <div className="content">
      <div className="label">
        <div className="colorBlock"></div>
        <div className="title">{catName}</div>
        <div className="dropdown">
          <div>
            {selectYear == AllYear ? AllYearText : selectYear + '年'}
          </div>
          <div className="dropdown_icon"></div>
          <div className="dropdown-content">
            {
              YearArray.map((year, y_index) => {
                return <div className="option" key={'option_' + y_index} onClick={() => {
                  setSelectYear(year)
                }}>
                  {year == AllYear ? AllYearText : year + '年'}
                </div>
              })
            }
          </div>
        </div>
      </div>
      <div className="list">
        {
          list.length !== 0 ? list.map((news, n_index) => {
            return <div className="news_item" key={'news_item_' + news.id} onClick={(e) => {
              if(getSelected()) return
              history.push(`/news/detail?id=${news.id}`)
            }}>
              <div className="left">
                <img src={imageTool.adjustWidth(news.coverImage, 310)} alt="新闻图片"></img>
              </div>
              <div className="right">
                <div className="box1">
                  <div className="title">{news.title}</div>
                  <div className="date">{moment(news.createTime).format('YYYY/MM/DD')}</div>
                </div>
                <div className="box2">
                  {news.intro}
                </div>
              </div>
            </div>
          }) : <div className="news_empty">暂无新闻</div>
        }
      </div>
      {
        list.length !== 0 ? <div className="pagination">
          <div className="prev_page" onClick={() => {
            if (currentPage == 1) return
            setCurrentPage(currentPage - 1)
          }}>
            <div className="button news_prev"></div>
          </div>
          {
            Array.from(Array(totalPage), (e, i) => {
              return <div className={`page ${currentPage == i + 1 ? 'active' : ''}`} key={i + 1} onClick={() => {
                setCurrentPage(i + 1)
              }}>{i + 1}</div>
            })
          }
          <div className="next_page" onClick={() => {
            if (currentPage == totalPage) return
            setCurrentPage(currentPage + 1)
          }}>
            <div className="button news_next"></div>
          </div>
        </div> : null
      }
    </div>
    <ScrollTopTool></ScrollTopTool>
    <Footer></Footer>
  </div>
}
export default NewsList;