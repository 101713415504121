import './index.scss';

const Slide_title = (props) => {
  return <div className="Slide_title">
  <div className="line_left"></div>
  <div className="title">{props.title}</div>
  <div className="line_right"></div>
</div>
}

export default Slide_title;