
// 解决create-react-app在ie中打开页面空白的兼容性问题:https://blog.csdn.net/qq_39471200/article/details/92655322
// npm install react-app-polyfill
import "core-js/es";
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";

// [Violation] Added non-passive event listener to a scroll-blocking ‘mousewheel‘ event.
// import 'default-passive-events';

import React, { Suspense, Component, useRef, useState, useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { isMobile } from '@utils';
import { Environment, HostPC_Dev, HostPC_Pro, HostMobile_Dev, HostMobile_Pro } from '@utils/constant';
import { getMenuData, getNewsCategoryNameById, getIndexData } from '@utils/api/common';

// -----------------路由部分--------------------------------
import { BrowserRouter, Route, Link } from "react-router-dom";
// BrowserRouter:HTML5 History API / HashRouter:Hash 模式路由
import Routes from '@router/router'

// 通用CSS文件
import '@/index.scss';
// [与后台系统一样的]第三方富文本组件Quill_Editor所需的css文件
import '@css/Quill_Editor/quill.bubble.css';
import '@css/Quill_Editor/quill.core.css';
import '@css/Quill_Editor/quill.snow.css';

// 导入swiper组件js和css
// import 'swiper/dist/js/swiper.js'
// import 'swiper/dist/css/swiper.min.css'

import { ConfigProvider } from 'antd';
// antd 目前的默认文案是英文
import zhCN from 'antd/lib/locale/zh_CN';

// Ant Design of React (https://ant.design/index-cn)
// 安装：
// npm install antd --save 或者 yarn add antd
// 引入样式：
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'

// react-redux
// import { Provider } from "react-redux";
// import { store, persistor } from "@store";
// redux-persist
// import { PersistGate } from 'redux-persist/lib/integration/react';

// const PersistApp = () => {
//   return <PersistGate loading={null} persistor={persistor}>
//     <BrowserRouter>
//       {Routes}
//     </BrowserRouter>
//   </PersistGate>
// }

const NormalApp = () => {
  useEffect(() => {
    let { href } = window.location
    if (Environment == 'production') {
      let toUrl = ''
      if (isMobile()) {
        if (href.includes(HostPC_Dev)) {
          toUrl = href.replace(HostPC_Dev, HostMobile_Dev)
        } else if (href.includes('www.'+HostPC_Pro)) {
          toUrl = href.replace('www.'+HostPC_Pro, HostMobile_Pro)
        } else if (href.includes(HostPC_Pro)) {
          toUrl = href.replace(HostPC_Pro, HostMobile_Pro)
        }
        if (toUrl) {
          console.log(toUrl)
          window.location.replace(toUrl)
        }
      }
    }
    // console.log('刷新页面和第一次进入官网')
    // getIndexData 获取首页数据和把【热销产品】存储起来
    // getMenuData 获取菜单栏数据和把【最新公告ID】存储起来
    // getIndexData(true)
    // getMenuData(true)
  }, [])
  return <BrowserRouter>
    {Routes}
  </BrowserRouter>
}
ReactDOM.render(
  <ConfigProvider locale={zhCN}>
    <NormalApp />
  </ConfigProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
