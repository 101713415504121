import './index.scss';
import Slide_title from '@component/Slide_title';
import moment from 'moment';
import { scrollSmoothToY } from '@utils';
import React, { Suspense, Component, useRef, useState, useCallback, useEffect } from 'react';
import imageTool from "@utils/imageTool";

const Sibu_Honor = (props) => {
  let [line, setLine] = useState(0)
  let row = 3
  let column = 4
  let honorList = props.data

  const adjustToWindow = useCallback(() => {
    let container = document.getElementById('honorList')
    let box = document.getElementsByClassName('honor_item')[line * column]
    if (!container || !box) return
    scrollSmoothToY(container, (box.clientHeight + parseInt(window.getComputedStyle(box).getPropertyValue('margin-bottom'))) * line)
  }, [column, line])
  window.onresize = () => {
    adjustToWindow()
  }
  useEffect(() => {
    adjustToWindow()
  }, [adjustToWindow])

  return <div className="Sibu_Honor">
    <Slide_title title={props.title}></Slide_title>
    <div className="content">
      <div className="honorList" id="honorList">
        {
          honorList?.map((honor, h_index) => {
            return honor.certImg ? <div className="honor_item" key={'honor_item' + h_index}>
              <img src={imageTool.adjustWidth(honor.certImg, 315)} className="honor_img" title={`${honor.event}(${moment(honor.rewardTime).format('YYYY/MM/DD')})`} alt=""></img>
              <div className="honor_name">
                {honor.honoraryTitle}
              </div>
            </div> : null
          })
        }
      </div>
      {
        honorList?.length > row * column ? <div className="pageOpts">
          <div className="opt partner_prev"
            style={{ opacity: `${line == 0 ? 0.4 : 1}` }}
            onClick={() => {
              if (line == 0) return
              setLine(line - 1)
            }}></div>
          <div className="opt partner_next"
            style={{ opacity: `${parseInt(honorList?.length / column) < line + row ? 0.4 : 1}` }}
            onClick={() => {
              if (parseInt(honorList?.length / column) < line + row)
                return
              setLine(line + 1)
            }}></div>
        </div> : null
      }
    </div>
  </div>
}

export default Sibu_Honor;

