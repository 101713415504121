import './index.scss';
import Header from '@/component/Header';
import Footer from '@/component/Footer';
import { useHistory, useLocation } from 'react-router';
import ScrollTopTool from '@/component/ScrollTopTool';
import React, { useEffect, useState } from 'react';
import Slide_title from '@component/Slide_title';
import Request from '@utils/request';
import { scrollSmoothToY, setDirectScrollTop, myBrowser } from '@utils';
import imageTool from "@utils/imageTool";

// 董事长信息 前端配置
const chairman_info = {
  name: '吴召国',
  avatarUrl: imageTool.adjustWidth('https://sibu-website.oss-cn-shenzhen.aliyuncs.com/pc/Boss_Avatar.png', 450)
}

const Enter_Sibu = (props) => {
  let history = useHistory()
  let location = useLocation()
  let anchor = location.state?.anchor || 1
  let [isOverBanner, setIsOverBanner] = useState('true') // 是否显示banner
  let [anchorName, setAnchorName] = useState('Anchor' + anchor)
  let [allData, setAllData] = useState({})
  let [array, setArray] = useState([])

  useEffect(() => {

    Request.get('/website/site/workIntoSibu').then(res => {
      if (res.errorCode == 0) {
        setAllData(res.result)
        if (location.state?.anchor) {
          setDirectScrollTop(0)
        } else {
          setDirectScrollTop(0)
          setAnchorName('Anchor' + anchor)
        }
      }
    })
  }, [anchor])// eslint-disable-line react-hooks/exhaustive-deps

  const AnchorConfig = [{
    id: 'Anchor1',
    title: '公司概况',
    render: () => {
      return <div className="richText ql-editor" dangerouslySetInnerHTML={{ __html: allData?.companyOverviewEntity?.overview }} />
    }
  }, {
    id: 'Anchor2',
    title: '企业荣誉',
    render: () => {
      return <div className="company_honors">
        {
          allData?.companyHonoraryList?.map((honor, h_index) => {
            return <div className="company_honor_item" key={'company_honor_' + h_index}>
              <div className="colorBlock"></div>
              <div className="desc">{honor}</div>
            </div>
          })
        }
      </div>
    }
  }, {
    id: 'Anchor3',
    title: '董事长荣誉',
    render: () => {
      return <div className="chairman_honors">
        <div className="avatar">
          <img src={chairman_info.avatarUrl} alt=""></img>
        </div>
        <div className="others">
          <div className="name">{chairman_info.name}</div>
          <div className="honors">
            {
              allData?.bossHonoraryList?.map((honor, h_index) => {
                return <div className="honor_item" key={'chairman_honor_' + h_index}>
                  {honor}
                </div>
              })
            }
          </div>
        </div>
      </div>
    }
  }, {
    id: 'Anchor4',
    title: '企业大事记',
    render: () => {
      return <div className="enterprise_deeds">
        <div className="midLine"></div>
        {
          allData?.progressVoList?.map((deeds, d_index) => {
            return <div className="deeds_item" key={'enterprise_deeds_' + d_index}>
              <div className="date">{deeds.evTime}</div>
              <div className="colorBlock"></div>
              <div className="deeds">{deeds.event}</div>
              <img className="pic" src={imageTool.adjustWidth(deeds.img, 310)} alt=""></img>
            </div>
          })
        }
      </div>
    }
  }, {
    id: 'Anchor5',
    title: '品牌产品',
    render: () => {
      return <div className="brand_products">
        {
          allData?.goodsCategoryVoList?.map((category, c_index) => {
            return <div className="category" key={'brand_products_category' + category.id}>
              <div className="label">
                <div className="innerlabel">
                  {category.catName}
                </div>
              </div>
              <div className="products">
                {
                  category?.goodsVoList?.map((product, p_index) => {
                    return <div className="product" key={'product' + product.id}>
                      <div className="goodsName" onClick={() => {
                        history.push(`/product/detail/${product.id}`, {
                          id: product.id
                        })
                      }}>
                        {product.goodsName}
                      </div>
                      {
                        p_index == category.goodsVoList?.length - 1 ? '' : '、'
                      }
                    </div>
                  })
                }
              </div>
            </div>
          })
        }
      </div>
    }
  }]

  const getAnchorsOffsetTop = () => {
    array = []
    AnchorConfig.forEach((xxxx, xxx_index) => {
      let anchorElement = document.getElementById(xxxx.id);
      if (!anchorElement) return
      array.push(anchorElement.offsetTop)
      setArray(array)
    })
  }
  const switchToAnchor = () => {
    let banner_img = document.getElementById("banner_img")
    if (!banner_img) return
    let scrollTop = document.body.scrollTop || document.documentElement.scrollTop
    array.forEach((a, a_index) => {
      if (a_index == array.length - 1) {
        if (scrollTop >= array[a_index] + banner_img.clientHeight) {
          setAnchorName('Anchor' + (a_index + 1))
        }
      } else {
        if (scrollTop >= array[a_index] + banner_img.clientHeight && scrollTop < array[a_index + 1] + banner_img.clientHeight) {
          setAnchorName('Anchor' + (a_index + 1))
        }
      }
    })
  }
  // 判断当前是否显示banner中
  const judgeIsShowBanner = () => {
    let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
    let banner_img = document.getElementById("banner_img")
    if (!banner_img) return
    if (scrollTop > banner_img.clientHeight) {
      setIsOverBanner('false')
    } else {
      setIsOverBanner('true')
    }
  }
  const Adjust = () => {
    judgeIsShowBanner()
    if (!myBrowser().trident) {
      // IE浏览执行监听页面滚动锚点自动跟随时,变得很卡,故在IE不执行该操作.
      getAnchorsOffsetTop()
      switchToAnchor()
    }
  }
  useEffect(() => {
    // 相当于 componentDidMount
    window.addEventListener("scroll", Adjust, false);
    window.addEventListener("resize", Adjust, false);
    // 相当于 componentWillUnmount
    return () => {
      // 需要清空全局事件
      window.removeEventListener("scroll", Adjust);
      window.removeEventListener("resize", Adjust);
    }
  })// 第二个参数代表监听所有的属性

  const scrollToAnchor = (anchorName) => {
    if (anchorName) {
      // 找到锚点
      let banner_img = document.getElementById("banner_img")
      let anchorElement = document.getElementById(anchorName);
      // 如果对应id的锚点存在，就跳转到锚点
      let element = document.body || document.documentElement
      if (anchorElement && banner_img) {
        scrollSmoothToY(element, anchorElement.offsetTop + banner_img.offsetHeight)
      }
    }
  }
  // 锚点导航器
  const Page_Anchor = () => {
    return <div className={`anchors ${isOverBanner == 'true' ? 'toAbsolute' : 'toFixed'}`}>
      {/* return <div className={`anchors toFixed`}> */}
      {
        AnchorConfig.map((anchor, a_index) => {
          return <div className={`item ${anchorName == anchor.id ? 'active' : ''}`} onClick={() => {
            setAnchorName(anchor.id)
            scrollToAnchor(anchor.id)
          }} key={`anchor_item${a_index}`}>
            {anchor.title}
          </div>
        })
      }
    </div>
  }
  // 锚点内容区
  const Anchor_content = () => {
    return <div className="Anchor_content" id="Anchor_content">
      <Page_Anchor></Page_Anchor>
      {
        AnchorConfig.map((anchor, a_index) => {
          return <div className="item_content" id={anchor.id} key={'Anchor_content_' + anchor.id}>
            <Slide_title title={anchor.title}></Slide_title>
            {
              anchor.render()
            }
          </div>
        })
      }
    </div>
  }

  // const setHeaderBGcolor = (color) => {
  //   document.getElementById('header').style.backgroundColor = color
  // }

  return <div className="Enter_Sibu">
    {/* <div style={{ width: '100%', position: 'fixed', top: 0, left: 0, zIndex: 999, background: isOverBanner == 'true' ? '#FFFFFF' : '#FFFFFF' }} id="header"
      onMouseOver={() => {
        setHeaderBGcolor('#FFFFFF')
      }}
      onMouseOut={() => {
        setHeaderBGcolor(isOverBanner == 'true' ? 'transparent' : '#FFFFFF')
      }}
    >
      <Header></Header>
    </div> */}
    <div style={{ width: '100%', position: 'fixed', top: 0, left: 0, zIndex: 999, background: '#FFFFFF' }} id="header">
      <Header></Header>
    </div>
    {/* <div id="banner_img"></div> */}
    <div className="content" id="content">
      <div id="banner_img"></div>
      <Anchor_content></Anchor_content>
    </div>
    <ScrollTopTool></ScrollTopTool>
    <Footer></Footer>
  </div>
}
export default Enter_Sibu;