import './index.scss';
import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Header from '@/component/Header';
import Footer from '@component/Footer';
import { Image } from 'antd';
import { Collapse } from 'antd';
import Request from '@utils/request';
import imageTool from "@utils/imageTool";

const { Panel } = Collapse;

const Query_Certificate = (props) => {
  let history = useHistory()
  let location = useLocation()

  let [certlist, setCertlist] = useState([])
  let [Panel_index, setPanel_index] = useState(location.state?.Panel_index || 0)
  let [Opt_index, setOpt_index] = useState(location.state?.Opt_index || 0)

  useEffect(() => {
    Request.get('/website/site/qualifyCert').then(res => {
      if (res.errorCode == 0) {
        setCertlist(res.result)
      }
    })
  }, [])

  const selectCollapse = (Panel_index, Opt_index) => {
    setPanel_index(Panel_index)
    setOpt_index(Opt_index)
    history.replace('/query/certificate', {
      Panel_index,
      Opt_index
    })
  }

  return <div className="Query_Certificate">
    <Header background={'#FFF'}></Header>
    <div className="content">
      <div className="label">
        <div className="colorBlock"></div>
        <div className="title">资质证书</div>
      </div>
      <div className="box">
        <div className="Collapse">
          <Collapse
            accordion={true}
            bordered={false}
            activeKey={Panel_index + 1}
            defaultActiveKey={['1']}
            expandIcon={({ isActive }) => null} // (panelProps) => ReactNode
            className="site-collapse-custom-collapse"
            onChange={(Panel_index) => {
              if (Panel_index == undefined) { // Panel_index 从1开始, undefined代表未展开任何
                selectCollapse(-1, -1)
              } else {
                selectCollapse(Panel_index - 1, 0)
              }
            }}
          >
            {
              certlist.map((category, c_index) => {
                return <Panel header={category.brandName} key={c_index + 1} className="site-collapse-custom-panel">
                  {
                    category?.goodsCertVoList?.map((product, p_index) => {
                      return <div className={`option ${c_index == Panel_index && p_index == Opt_index ? 'active' : ''}`} key={`product_${product.goodsId}`} onClick={() => {
                        selectCollapse(Panel_index, p_index)
                      }}>{product.goodsName}</div>
                    })
                  }
                </Panel>
              })
            }
          </Collapse>
        </div>
        {
          Panel_index >= 0 ? <div className="Certificate">
            <div className="productName">
              {certlist[Panel_index]?.goodsCertVoList[Opt_index]?.goodsName}
            </div>
            <div className="certificateList">
              <Image.PreviewGroup>
                {
                  certlist[Panel_index]?.goodsCertVoList[Opt_index]?.quaVoList?.map((cert, c_index) => {
                    return <Image src={imageTool.adjustHeight(cert.img, 1080)} key={cert.certId} alt={cert.certName} placeholder={true} />
                  })
                }
              </Image.PreviewGroup>
            </div>
          </div> : <div className="Certificate"></div>
        }
      </div>
      <div style={{ width: '100%', position: 'absolute', bottom: 0, left: 0, zIndex: 999 }}>
        <Footer></Footer>
      </div>
    </div>

  </div>
}

export default Query_Certificate

