import { Route, Redirect } from "react-router-dom";
import { CacheRoute, CacheSwitch } from 'react-router-cache-route'

// 初始化路由对象数组
let Routes = []

// 针对配置项，返回单个路由对象
const RouteItem = props => {
  const { redirect, path, component, key, isCache } = props
  // console.log(props)
  if (redirect) {
    return <Redirect exact key={key} from={path} to={redirect} />
  }
  /*
  react-router-cache-route 提供的CacheSwitch和CacheRoute
  搭配 react-router 工作的、带缓存功能的路由组件，类似于 Vue 中的 keep-alive 功能
  其中 使用 when 属性决定何时使用缓存功能，可选值为 [forward, back, always] ，默认值为 forward
  */
  // return <CacheRoute when="always" exact key={key} component={component} path={path} />
  return isCache == true ?
    <CacheRoute when="always" exact key={key} component={component} path={path} />
    :
    <Route exact key={key} component={component} path={path} />
}

// 获取子路由
const loopRoute = (route, i, pre_path, pre_key) => {
  return route.children.forEach((routeChild, idx) => {
    let __path = pre_path + routeChild.path
    let __key = `${pre_key}-${idx}`
    const { redirect, component, children, isCache } = routeChild
    if (children && children.length) {
      // 递归获取子路径
      if (component) {
        Routes = Routes.flat()
        Routes.push(RouteItem({
          key: __key,
          redirect,
          path: __path,
          component: component,
          isCache: isCache
        }))
      }
      loopRoute(routeChild, idx, __path, __key)
    } else {
      Routes.push(RouteItem({
        key: __key,
        redirect,
        path: __path,
        component: component,
        isCache: isCache
      }))
    }
  })
}

// 根据配置对象生成路由对象数组
// 1. 放置 redirect or component, 且redirect优先级大于component
// 2. 遍历 children 节点的路由对象
const createRouter = (RouterConfig) => {
  RouterConfig.forEach((route, key) => {
    if (route.redirect || route.component) {
      Routes.push(RouteItem({ key, ...route }))
    }
    return Array.isArray(route.children) && route.children.length
      ? loopRoute(route, key, route.path, key)
      : ''
  })
}

// 获取路由对象数组
const getRouter = () => {
  return Routes
}

export {
  createRouter,
  getRouter
};