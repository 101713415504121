import './index.scss';
import { useLocation, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import imageTool from "@utils/imageTool";


const Digital_business = (props) => {
  let history = useHistory()
  return <div className="Digital_business">
    <div className="main"
      style={{ backgroundImage: `url('${imageTool.adjustWidth(props.bannerTwoVo?.image, 1920)}')` }}
    >
      <div className="center">
        <div className="left">
          <div className="line1">数字微商</div>
          <div className="line2">经营一盘赚钱的小生意 </div>
          <div className="line3">
            数字微商是传统微商的3.0升级版，利用大数据、聚量涡轮、智能化管理等现代互联网手段，从品牌、个人实力、流量等维度为微商多重赋能，从而打破传统微商固有局限，让经营者轻松地把优质商品传播至更广阔的大众市场，真正实现简单操作、高效转化，重构微商新业态。
          </div>
          <div className="line4">
            数字微商，将传统微商带入到数字时代。数字化程序将经营者从低效传播中解放出来，从而能够更加高效、更加专业地传播商品、服务客户。经营者单位时间的盈利效率得到有效提高。同时，购买流程的方便让消费者对产品的信任度大大提高，形成双向互利互惠的良性循环发展。
          </div>
          <div className="line5">
            微商向系统化、透明化、大众化健康发展，实现让小生意更简单。
          </div>
        </div>
        <div className="right"></div>
      </div>
    </div>
  </div>
}

export default Digital_business;