import React, { useState, useEffect } from 'react';
import './index.scss';
import Header from '@/component/Header';
import { getIndexData } from '@utils/api/common';
import Vertical_swiper from './Vertical_swiper';

const Home = props => {
  let [indexData, setIndexData] = useState({})

  // useEffect(() => {
    // getIndexData().then((data) => {
    //   setIndexData(data)
    // })
  // }, [])

  return <div className="Home">
    {/* <div className="Header">
      <Header background={'#FFF'}></Header>
    </div>
    <Vertical_swiper indexData={indexData}></Vertical_swiper> */}
    <div className="swiper_img" style={{ backgroundImage: `url(https://xls-static.sibumbg.com/4b0a3907a2bf4da0a9eca0e48d991a7d.png)` }}></div>
  </div>
}

export default Home