import React, { Component, useRef, useState, useCallback } from 'react';
import './index.scss';
// 导入基本swiper组件
import Swiper from "swiper";
import 'swiper/dist/js/swiper.js';
import 'swiper/dist/css/swiper.min.css';
import { withRouter } from "react-router-dom";
import Horizontal_swiper from './Horizontal_swiper';
import Digital_business from '@view/Home/Digital_business';
import Product_slide from '@view/Home/Product_slide';
import Latest_news from '@view/Home/Latest_news';
import Video_slide from '@view/Home/Video_slide';
import Partner_slide from '@view/Home/Partner_slide';


class Vertical_swiper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialSlide: 0
    };
  }
  componentDidMount() {
    new Swiper(".swiper-container-v", {
      slidesPerView: 1,//同屏显示多少个swiper滑块
      autoplay: false,//自动轮播开启
      direction: 'vertical',
      spaceBetween: 0,// 与下一屏的空白距离
      initialSlide: this.state.initialSlide,//默认展示第几个滑块
      loop: false,//是否开启无限轮播
      navigation: true,
      mousewheel: true,
      pagination: {
        el: '.swiper-pagination-v',//开启分页器操作
        clickable: true
      },
      lazy: {
        loadPrevNext: true,
      },
      observer: true,//修改swiper自己或子元素时，自动初始化swiper
      observeParents: true,//修改swiper的父元素时，自动初始化swiper
    });

    // noswiping={true ? 1 : 0} //设为true时，可以在slide上（或其他元素）增加类名'swiper-no-swiping'，使该slide无法拖动，希望文字被选中时可以考虑使用。
    //     // error: Received `true` for a non-boolean attribute `noswiping`, React对boolean类型的attribute的识别方式问题, 改成数字的写法，不用布尔值。
    // noSwipingClass={'noSwipingClass'}
  }
  render() {
    let props = this.props
    return <div className="vertical_swiper">
      <div className="swiper-container swiper-container-v">
        {/* 分页器 */}
        <div className="swiper-pagination swiper-pagination-v"></div>
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <Horizontal_swiper indexData={props.indexData}></Horizontal_swiper>
          </div>
          <div className="swiper-slide">
            <Digital_business title="数字微商" bannerTwoVo={props.indexData.bannerTwoVo}></Digital_business>
          </div>
          <div className="swiper-slide">
            <Product_slide title="品牌中心" productSetVoList={props.indexData.productSetVoList}></Product_slide>
          </div>
          <div className="swiper-slide">
            <Latest_news title="最新动态" indexNewsVoList={props.indexData.indexNewsVoList}></Latest_news>
          </div>
          <div className="swiper-slide">
            <Video_slide title="视频中心" indexVideoVoList={props.indexData.indexVideoVoList}></Video_slide>
          </div>
          <div className="swiper-slide">
            <Partner_slide title="战略合作伙伴" indexPartnerVoList={props.indexData.indexPartnerVoList}></Partner_slide>
          </div>
        </div>
      </div>
    </div>
  }
}

export default withRouter(Vertical_swiper);