// 获取从 target 目标年份到当前年份的数组
export function getYearArray(target) {
  var myDate = new Date();
  var thisYear = myDate.getFullYear();  // 获取当年年份
  var Section = thisYear - target;  // 声明一个变量 获得当前年份至想获取年份差 eg.2008
  var arrYear = []; // 声明一个空数组 把遍历出的年份添加到数组里
  for (var i = 0; i <= Section; i++) {
    arrYear.push(thisYear--)
  }
  return arrYear
}