import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './index.scss';
import Slide_title from '@component/Slide_title';
import imageTool from "@utils/imageTool";

const Video_slide = (props) => {
  let history = useHistory()
  let [pageSize, setPageSize] = useState(6)
  // let [playId, setPlayId] = useState(-1)
  // const play_video = (id) => {
  //   setPlayId(id)
  //   props.indexVideoVoList?.map((v, v_index) => {
  //     if (v.id != id) {
  //       document.getElementById(v.id).pause()
  //     }
  //   })
  // }
  return <div className="Video_slide">
    <div className="main">
      <div className="center">
        <Slide_title title={props.title}></Slide_title>
        <div className="videoList">
          {
            props.indexVideoVoList?.map((video, v_index) => {
              return <div className="video_item" key={video.id} onClick={() => {
                history.push('/video?id=' + video.id)
              }}>
                {
                  <div className="video_mask" >
                    <div className="play_button"></div>
                  </div>
                }
                <img className="video" src={imageTool.adjustWidth(video.coverImage, 425)} alt="视频封面"></img>
              </div>
            })
          }
          {/* 补足screenSize(6)的倍数 */}
          {
            props.indexVideoVoList?.length < 6 ? [...Array(pageSize - props.indexVideoVoList?.length % pageSize)].map((e, i) => {
              return <div className="video_item invisible" key={'empty_' + i}></div>
            }) : null
          }
        </div>
        <div className="seeMore_button" onClick={() => {
          history.push('/video')
        }}>查看更多</div>
      </div>
    </div>
  </div>
}

export default Video_slide;