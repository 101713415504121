import React, { Component, useRef, useState, useCallback } from 'react';
import './index.scss';
// 导入基本swiper组件
import Swiper from "swiper";
import 'swiper/dist/js/swiper.js';
import 'swiper/dist/css/swiper.min.css';
import { withRouter } from "react-router-dom";
import imageTool from "@utils/imageTool";

class Detail_Swiper extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    new Swiper(`.swiper-container-ProductDetail`, {
      slidesPerView: 4,//同屏显示多少个swiper滑块
      autoplay: false,//自动轮播开启
      direction: 'horizontal',
      mousewheel: false,
      spaceBetween: 0,// 与下一屏的空白距离
      initialSlide: 0,//默认展示第几个滑块
      loop: false,//是否开启无限轮播
      lazy: {
        loadPrevNext: true,
      },
      navigation: {
        nextEl: '.swiper-button-next-777',
        prevEl: '.swiper-button-prev-777'
      },
      observer: true,//修改swiper自己或子元素时，自动初始化swiper
      observeParents: true,//修改swiper的父元素时，自动初始化swiper
    });
  }
  render() {
    let product = this.props.data
    return product ? <div className="Products_swiper">
      {/* 重写覆盖部分swiper代码 */}
      {/* 1. swiper 本身的箭头在里面；而我们需要箭头在外面； */}
      {/* 在swiper-container(<Swiper>) 外面增加一个包裹，也就是套一个div(Products_swiper)，给这个div增加相对定位position:relative;*/}
      {/* 把左右箭头的代码添加在swiper-container外面同一级 */}
      {/* 2. 因为此页面有多个swiper，因为翻页器className需添加指定key
      navigation={{
        nextEl: '.swiper-button-next' + props.current_index,
        prevEl: '.swiper-button-prev' + props.current_index
      }} */}
      <div className={`swiper-button-prev-777 ${product.photos?.length <= 4 ? 'swiper-button-disabled' : ''}`}>
        <div className="button gggg_prev_icon"></div>
      </div>
      <div className={`swiper-button-next-777 ${product.photos?.length <= 4 ? 'swiper-button-disabled' : ''}`}>
        <div className="button gggg_next_icon"></div>
      </div>
      <div className="swiper-container swiper-container-ProductDetail">
        <div className="swiper-wrapper">
          {
            product.photos?.map((img, img_index) => {
              return <div className="swiper-slide" key={`product_${img_index}`}>
                <div className={`product_item`} onClick={() => {
                  this.props.onSelectItem({
                    BannerIndex: img_index,
                    AttrIndex: -1,
                    Banner: img,
                    Price: product.price
                  })
                }}>
                  <div className={`img ${this.props.bannerIndex == img_index ? 'active' : ''}`}>
                    <img src={imageTool.adjustWidth(img, 112)} alt=""></img>
                  </div>
                </div>
              </div>
            })
          }
          {/* 少于4个需要补足4个 */}
          {
            product.photos?.length < 4 ? [...Array(4 - product.photos?.length)].map((e, i) => {
              return <div className="swiper-slide" key={`empty_${i}`}>
                <div className="product_item"></div>
              </div>
            }) : null
          }
        </div>
      </div>
    </div> : null
  }
}

export default withRouter(Detail_Swiper);
