import Home from '@/view/Home';
import NotFound from '@/view/404';


const routes_Home = [
  {
    path: '/',
    component: Home,
    isCache: true
  },
  {
    path: '/404',
    component: NotFound,
    isCache: false
  },
]

export default routes_Home
